import React, { useState, createContext, useEffect } from 'react';

export const myContext = createContext("")

const Provider = props => {
    const [isLoc, setLoc] = useState(false);
    const [isCountry, setCountry] = useState(false);
    const [isNearby, setNearby] = useState([]);

    useEffect(() => {
    fetch(`https://geo.golfip.workers.dev/`)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
            setCountry(resultData.country)
            setLoc(resultData)
            console.log(resultData)
        }).catch((error) => {
            //navigate('/')
        })
    }, [])

    useEffect(() => {
        // get nearby locations - used site wide
        fetch(`https://data.golfip.com/wp-json/search/v1/search/?type=loc&lat=${isLoc.latitude}
&lng=${isLoc.longitude}`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setNearby(resultData)
            }).catch((error) => {
                console.log(error)
            })
    }, [isLoc])

    return (
        <myContext.Provider value={{
            isLoc, changeLoc: (test) => { setLoc(test) }, isCountry, isNearby, changeNearby: (test) => { setNearby(test) }
        }}>
            {props.children}
        </myContext.Provider>
    )
}

export default ({ element }) => (
    <Provider>
        {element}
    </Provider>
)